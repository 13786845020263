import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link } from 'gatsby';
import Image from '../elements/Image';

import 'swiper/css/pagination';

export const ArticleRelatedResources = (props) => {
  const { relatedHeading, relatedItem } = props;

  return (
    <div className="pt-1 pb-32 container">
      <h3 className="text-center t-38 text-black font-bold mb-16">{relatedHeading}</h3>
      <Swiper
        modules={[Pagination]}
        className="max-w-[50rem]"
        spaceBetween={50}
        pagination={{ clickable: true, el: '.pagination' }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          660: {
            slidesPerView: 3,
          },
        }}
      >
        {relatedItem?.map((item, i) => (
          <SwiperSlide key={i}>
            <Link to={item?.postItem?.uri}>
              <div className="aspect-[265/214] mb-9 rounded-lg overflow-hidden safari-overflow">
                <Image
                  className="w-full h-full object-cover object-center"
                  objectFit={'cover'}
                  image={item?.postItem?.featuredImage?.node}
                />
              </div>
              <h4 className="t-20-h text-black font-bold leading-[1.5]">{item?.postItem?.title}</h4>
              <div
                className="text-black/60 line-clamp-3"
                dangerouslySetInnerHTML={{ __html: item?.postItem?.postTypeSingle?.previewgroup?.previewDescription }}
              ></div>
            </Link>
          </SwiperSlide>
        ))}
        <div className="pt-12 related-resource-pagination pagination flex justify-center" slot="pagination"></div>
      </Swiper>
    </div>
  );
};
