import { Field, useField } from 'formik';
import React from 'react';

export default function SignUpField(props) {
  const { label, description, required=true , ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const {setError} = helpers;

  return (
    <label className={`flex flex-col text-darkestNavy items-start flex-auto ${props.className || ''}`}>
      <div className="relative w-full">
        <Field
          type="text"
          placeholder='Email'
          className="w-full h-[40px] text-center sm:text-left pl-4 pr-8 md:pl-8 md:pr-12 py-3 rounded-[20px] placeholder-black placeholder-opacity-50 font-medium flex-1 leading-4 border-borderGrey focus:!border-blue border transition-all duration-200"
          {...field}
          {...other}
          validate={(value) => { if(required){ if(!value) return 'This field is required'; }  }}
        ></Field>
      </div>
      {isInvalid && <div className="text-error text-[15px] italic text-red">{meta.error}</div>}
    </label>
  );
}
