import React from 'react';
import Image from '../elements/Image';
import { motion } from 'framer-motion';
import DateFormat from '../elements/Date';
import { Link } from 'gatsby';
import { ChevronBlk } from '../elements/Icon';

import { ArticleShareBar } from './ArticleShareBar';

export const ArticleMasthead = ({ returnLink, content, author, date, wp, categories, hideAuthor = false }) => {
  return (
    <section>
      <div className="container pt-28 md:pt-40 lg:pt-[180px] mb-16 md:mb-32 lg:mb-[120px]">
        <div className="mx-auto max-w-[50rem]">
          <div className="mb-8 md:mb-12">
            <Link to={returnLink} className="flex space-x-2 items-center t-15">
              <ChevronBlk />
              <span>Back to previous page</span>
            </Link>
          </div>
          {content.addImage && (
            <motion.div
              initial={{ scale: 1, opacity: 0 }}
              animate={{ scale: 1, opacity: 1, transition: { duration: 0.5 } }}
            >
              <div className="rounded-[10px] overflow-hidden safari-overflow mb-10 md:mb-[65px]">
                <Image image={content?.image} />
              </div>
            </motion.div>
          )}
          <div className="mx-auto max-w-[840px] mb-8 md:mb-[65px]">
            {content?.useCategoryAsSubheading ? (
              <h6 className="mb-6 md:mb-[35px]">
                {categories?.map((category, id, array) => (
                  <span
                    key={`postcategory${id}`}
                    className="border rounded-[50px] px-4 py-1 uppercase font-body font-medium t-12 mr-2"
                  >
                    {category?.name}
                  </span>
                ))}
              </h6>
            ) : (
              <h6 className="text-blue uppercase font-bold !font-body t-14 tracking-widest mb-6 md:mb-[35px]">
                {content?.subheading}
              </h6>
            )}
            <h1 className="t-52-a mb-4 font-medium">{content?.heading}</h1>
            <p
              className="t-18 max-w-[630px] leading-[1.4]"
              dangerouslySetInnerHTML={{ __html: content?.description }}
            />
          </div>
          <div
            className={`rounded-[6px] bg-lightBlue p-6 md:px-8 md:pt-8 md:pb-[40px] flex flex-col md:flex-row md:justify-between mb-8 md:mb-[48px]`}
          >
            {!hideAuthor ? (
              <>
                <div className="sm:flex space-y-6 md:space-y-4 sm:space-x-8">
                  <div className="rounded-[6px] overflow-hidden safari-overflow w-[44px] h-[44px]">
                    <Image image={author?.profilePicture} className="w-full h-full object-cover object-center" />
                  </div>
                  <div className="md:!mt-0">
                    <h4 className="t-20 font-bold mb-2">{author?.displayName}</h4>
                    <p className="t-16 max-w-[380px] leading-[1.4]" dangerouslySetInnerHTML={{ __html: author?.bio }} />
                  </div>
                </div>
                <div className="t-14 font-normal md:pr-[30px] lg:pr-[60px] pt-8">
                  <div className="grid grid-cols-2 mb-[18px]">
                    <h6 className="opacity-50">Posted on</h6>
                    <h6>
                      <DateFormat dateString={date} />
                    </h6>
                  </div>
                  <div className="grid grid-cols-2">
                    <h6 className="opacity-50">Read time</h6>
                    <h6>{content?.readTime}</h6>
                  </div>
                </div>
              </>
            ) : (
              <div className="t-14 font-normal md:pr-[30px] lg:pr-[60px] pt-2 w-full flex flex-col md:flex-row gap-2 md:gap-0">
                <div className="flex md:w-1/2">
                  <h6 className="opacity-50 mr-6">Posted on</h6>
                  <h6>
                    <DateFormat dateString={date} />
                  </h6>
                </div>
                <div className="flex md:w-1/2">
                  <h6 className="opacity-50 mr-6">Read time</h6>
                  <h6>{content?.readTime}</h6>
                </div>
              </div>
            )}
          </div>
          <ArticleShareBar />
        </div>
        <hr className="border-t border-borderGrey2 mx-auto" />
      </div>
    </section>
  );
};
