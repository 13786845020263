import React, {useState} from "react";
import { Twitter, Facebook, Linkedin } from "../elements/Icon";
import { Star } from "../elements/forms/SignUpForm";
import { Link } from "gatsby";
import Socials from "../global/Socials";
import { Form, Formik, useFormik, Field, useField } from 'formik';
import SignUpField from "../elements/forms/SignUpField";


export const ArticleShareBar = () =>{


  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setIsSubmitted] = useState(false);

  return(
    <div className="mb-8 md:mb-[40px] md:flex space-y-6 md:space-y-0 md:space-x-6 md:justify-between">
      <div className="w-full mx-auto md:ml-0 max-w-[250px] flex justify-between items-center space-x-2">
        <Socials altFill="fill-[#2554fd]" altFillHover="hover:fill-[#12C0C0]" />
      </div>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={async (values, { setFieldValue }) => {
          const formData = new FormData();

          for (var key in values) {
            formData.append(key, values[key]);
          }

          fetch("https://hooks.zapier.com/hooks/catch/10170231/bw3oatv/", {
            method: "POST",
            body: formData,
          })
            .then((response) => {
              if (response.status === 200) {
                setIsSubmitted(true);
              }
            })
            .catch((error) => {
              setErrorMessage(error);
            });
          setErrorMessage(""); // reset error message
        }}
        >
        <Form className="text-left max-w-[450px]  mx-auto md:mr-0">
          <div className="sm:flex font-heading space-y-4 sm:space-y-0">
            <fieldset className="sm:-mr-8 t-18 w-full sm:w-[95%]">
               <SignUpField name="email" label="Email Address" type="email" />
            </fieldset>
            <button
              className="z-10 w-full sm:w-auto bg-blue text-white rounded-[20px] h-[40px] px-[30px] flex items-center t-16 font-bold space-x-[9px] whitespace-nowrap justify-center mx-auto md:ml-0"
              type="submit"
              >
                <span>Subscribe</span>
                <Star />
            </button>
          </div>
          {submitted && (
              <div className="p-4 mt-4 text-blue text-center">
                Thanks for Signing Up.
              </div>
            )}
        </Form>
      </Formik>
    </div>
  );
};